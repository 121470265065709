/* src/styles/submitApplication.css */
.application-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
}

.application-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.application-form .form-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.application-form .textarea-field {
  height: 100px; /* Adjust the height as needed */
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #ffa500;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #e69500;
}
