/* src/styles/postRequest.css */
.post-request-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
}

.post-request-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.request-form .form-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.request-form .textarea-field {
  height: 100px; /* Or any height you prefer */
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #ffa500;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #e69500;
}
